import React, { useState, useEffect, useRef } from "react";
import { parseISO, format } from "date-fns";
import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from "@material-ui/core/Switch";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { TagsContainer } from "../TagsContainer";
import useQueues from "../../hooks/useQueues";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},

  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ContactSchema = Yup.object().shape({
  name: Yup.string().min(2, "Too Short!").max(250, "Too Long!").required("Required"),
  number: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
  email: Yup.string().email("Invalid email"),
  aniversario: Yup.string()
    .nullable()
    .matches(/^\d{2}\/\d{2}\/\d{4}$/, "Formato inválido, use 'dd/mm/aaaa'"),
  cpfcnpj: Yup.string()
    .nullable()
    .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$|^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, "Formato inválido, use '000.000.000-00' ou '00.000.000/0000-00'"),
  queuepref: Yup.string().nullable(),
});

const ContactModal = ({ open, onClose, contactId, initialValues, onSave }) => {
  const classes = useStyles();
  const isMounted = useRef(true);
  const { findAll: findAllQueues } = useQueues();
  const [queues, setQueues] = useState([]);
  const [selectedQueue, setSelectedQueue] = useState("");
  const [contact, setContact] = useState({
    name: "",
    number: "",
    email: "",
    aniversario: "",
    cpfcnpj: "",
    queuepref: "",
    disableBot: false,
    lgpdAcceptedAt: "",
  });
  const [disableBot, setDisableBot] = useState(false);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchQueues = async () => {
      try {
        const list = await findAllQueues();
        setQueues(list);
      } catch (err) {
        toastError(err);
      }
    };
    fetchQueues();
  }, []);

  useEffect(() => {
    const fetchContact = async () => {
      if (initialValues) {
        setContact({ ...initialValues });
        setSelectedQueue(initialValues.queuepref || "");
      }

      if (!contactId) return;

      try {
        const { data } = await api.get(`/contacts/${contactId}`);
        if (isMounted.current) {
          setContact(data);
          setSelectedQueue(data.queuepref || "");
          setDisableBot(data.disableBot);
        }
      } catch (err) {
        toastError(err);
      }
    };

    fetchContact();
  }, [contactId, open, initialValues]);

  const handleClose = () => {
    onClose();
    setContact({
      name: "",
      number: "",
      email: "",
      aniversario: "",
      cpfcnpj: "",
      queuepref: "",
      disableBot: false,
      lgpdAcceptedAt: "",
    });
    setSelectedQueue("");
  };

  const handleSaveContact = async (values) => {
    try {
      const updatedValues = {
        ...values,
        queuepref: selectedQueue,
        disableBot: disableBot,
      };
      if (contactId) {
        await api.put(`/contacts/${contactId}`, updatedValues);
        handleClose();
      } else {
        const { data } = await api.post("/contacts", updatedValues);
        if (onSave) {
          onSave(data);
        }
        handleClose();
      }
      toast.success(i18n.t("contactModal.success"));
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
        <DialogTitle>
          {contactId ? i18n.t("contactModal.title.edit") : i18n.t("contactModal.title.add")}
        </DialogTitle>
        <Formik
          initialValues={contact}
          enableReinitialize={true}
          validationSchema={ContactSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveContact(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
			<DialogContent dividers className={classes.dialogContent}>
				<Typography variant="subtitle1" gutterBottom className={classes.subTitle}>
					{i18n.t("contactModal.form.mainInfo")}
				</Typography>
                <Field
                  as={TextField}
                  label={i18n.t("contactModal.form.name")}
                  name="name"
                  autoFocus
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                />
                <Field
                  as={TextField}
                  label={i18n.t("contactModal.form.number")}
                  name="number"
                  error={touched.number && Boolean(errors.number)}
                  helperText={touched.number && errors.number}
                  placeholder="5513912344321"
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                />
				<div className={classes.row}>
							<Field
								as={TextField}
								label={"Data de Aniversário"}
								name="aniversario"
								error={touched.aniversario && Boolean(errors.aniversario)}
								helperText={touched.aniversario && errors.aniversario}
								placeholder="25/09/1992"
								variant="outlined"
								margin="dense"
								className={classes.textField}
							/>
							<Field
								as={TextField}
								label={"CPF"}
								name="cpfcnpj"
								error={touched.cpfcnpj && Boolean(errors.cpfcnpj)}
								helperText={touched.cpfcnpj && errors.cpfcnpj}
								placeholder="999.999.999-99"
								variant="outlined"
								margin="dense"
								className={classes.textField}
							/>
						</div>
                <FormControl variant="outlined" fullWidth className={classes.textField}>
                  <InputLabel>{"Fila Preferencial"}</InputLabel>
                  <Select
                    value={selectedQueue}
                    onChange={(e) => setSelectedQueue(e.target.value)}
                    label={"Fila Preferencial"}
                  >
                    {queues.map((queue) => (
                      <MenuItem key={queue.id} value={queue.id}>
                        {queue.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Field
                  as={TextField}
                  label={i18n.t("contactModal.form.email")}
                  name="email"
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  placeholder="Email address"
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  className={classes.textField}
                />
						
						<Typography variant="subtitle1" className={classes.switchTitle}>
							<Switch
								size="small"
								checked={disableBot}
								onChange={() => setDisableBot(!disableBot)}
								name="disableBot"
								className={classes.switch}
							/>
							{i18n.t("contactModal.form.chatBotContact")}
						</Typography>

						<Typography variant="subtitle1" className={classes.whatsappInfo}>
							{i18n.t("contactModal.form.whatsapp")} {contact?.whatsapp ? contact?.whatsapp.name : ""}
						</Typography>

						<Typography variant="subtitle1" className={classes.termsInfo}>
							{i18n.t("contactModal.form.termsLGDP")} {contact?.lgpdAcceptedAt ? format(new Date(contact?.lgpdAcceptedAt), "dd/MM/yyyy 'às' HH:mm") : ""}
						</Typography>
						
						<Typography variant="subtitle1" className={classes.extraInfoTitle}>
							{i18n.t("contactModal.form.extraInfo")}
						</Typography>
						
						<FieldArray name="extraInfo">
							{({ push, remove }) => (
								<>
									{values.extraInfo && values.extraInfo.length > 0 &&
										values.extraInfo.map((info, index) => (
											<div className={classes.extraAttr} key={`${index}-info`}>
												<Field
													as={TextField}
													label={i18n.t("contactModal.form.extraName")}
													name={`extraInfo[${index}].name`}
													variant="outlined"
													margin="dense"
													className={classes.textField}
												/>
												<Field
													as={TextField}
													label={i18n.t("contactModal.form.extraValue")}
													name={`extraInfo[${index}].value`}
													variant="outlined"
													margin="dense"
													className={classes.textField}
												/>
												<IconButton size="small" onClick={() => remove(index)} className={classes.removeIcon}>
													<DeleteOutlineIcon />
												</IconButton>
											</div>
										))}
									<Button variant="outlined" color="primary" onClick={() => push({ name: "", value: "" })} className={classes.addButton}>
										{`+ ${i18n.t("contactModal.buttons.addExtraInfo")}`}
									</Button>
								</>
							)}
						</FieldArray>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="secondary" disabled={isSubmitting} variant="outlined" className={classes.cancelButton}>
							{i18n.t("contactModal.buttons.cancel")}
						</Button>
						<Button type="submit" color="primary" disabled={isSubmitting} variant="contained" className={classes.submitButton}>
							{contactId
								? `${i18n.t("contactModal.buttons.okEdit")}`
								: `${i18n.t("contactModal.buttons.okAdd")}`}
							{isSubmitting && (
								<CircularProgress size={24} className={classes.buttonProgress} />
							)}
						</Button>
					</DialogActions>
				</Form>
			)}
		</Formik>
		</Dialog>
	</div>
	);
};

export default ContactModal;