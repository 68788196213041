import openSocket from "socket.io-client";
import { isObject } from "lodash";
import { getBackendUrl } from "../config";
import SocketWorker from "./SocketWorker";

export function socketConnection(params) {
  let userId = "";
  let companyId = "";

  // Verifica se params é um objeto e extrai os IDs
  if (isObject(params)) {
    companyId = params?.user?.companyId;
    userId = params?.user?.id;
  }

  // Obtém o token e valida
  const token = localStorage.getItem("token");
  if (!token) {
    console.error("Token não encontrado. A conexão não pode ser estabelecida.");
    return null; // Retorna null se o token não existir
  }

  // Estabelece a conexão segura com o servidor
  const socket = openSocket(getBackendUrl(), {
    transports: ["websocket", "polling", "flashsocket"],
    query: {
      token: JSON.parse(token),
      companyId: companyId,
      userId: userId,
    },
  });

  // Executa o SocketWorker após a conexão segura
  return SocketWorker(companyId, userId, socket);
}
