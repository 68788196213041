import React, { useContext, useEffect, useRef, useState, useReducer } from "react";
import {
  Box,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  makeStyles,
  Paper,
  Typography,
  Avatar,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import UserStatusIcon from "../../components/UserModal/statusIcon";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useDate } from "../../hooks/useDate";
import api from "../../services/api";
import { toast } from "react-toastify";
import grupimg from '../../assets/chaticon/nopicture.png'; // Importando a imagem

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    overflow: "hidden",
    borderRadius: 0,
    height: "100%",
    padding: "0px",
  },
  usersBar: {
    marginBottom: "10px",
    padding: "10px",
    background: theme.palette.grey[200],
    display: 'flex', // Para alinhar a imagem e o texto
    alignItems: 'center', // Centralizar verticalmente
  },
  userImage: {
    width: 80, // Tamanho da imagem
    height: 80,
    background: "#f2f2f2",
    borderRadius: "50%",
    marginRight: "-4%", // Espaço entre a imagem e o texto
  },
  messageList: {
    position: "relative",
    overflowY: "auto",
    height: "100%",
    ...theme.scrollbarStyles,
    backgroundColor: theme.mode === 'light' ? "#f2f2f2" : "#7f7f7f",
  },
  inputArea: {
    position: "relative",
    height: "auto",
  },
  input: {
    padding: "20px",
  },
  buttonSend: {
    margin: theme.spacing(1),
  },
  boxLeft: {
    padding: "10px 10px 5px",
    margin: "10px", //espaço entre os usuarios
    position: "relative",
    backgroundColor: "#ffffff",
    color: "#303030",
    maxWidth: "70%",
    borderRadius: 10,
    borderBottomLeftRadius: 0,
    border: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  boxRight: {
    padding: "10px 10px 5px",
    margin: "10px 10px 10px auto",
    position: "relative",
    backgroundColor: "#dcf8c6",
    color: "#303030",
    maxWidth: "70%",
    borderRadius: 10,
    borderBottomRightRadius: 0,
    border: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  avatar: {
    width: 70,
    height: 70,
    borderRadius: "0%",
    marginRight: "2%",
  },
  avatarLarge: {
    width: 20,
    height: 20,
    borderRadius: "10%",
    marginRight: 10,
  },
  messageContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    wordWrap: "break-word",
    overflowWrap: "break-word",
    maxWidth: "100%",
    overflow: "hidden",
    whiteSpace: "normal",
  },
  messageText: {
    maxWidth: "32ch", // Ou outro limite para o texto
    whiteSpace: "pre-wrap", // Permite quebras de linha e múltiplos espaços
    wordWrap: "break-word", // Garante quebra de palavras grandes
    overflowWrap: "break-word", // Mesma ideia para navegadores mais antigos
  },
  usersBar: {
    padding: '10px',
    backgroundColor: '#8a9aa8', //cor da barra de titulo
    color: '#000000',   //cor do titulo
    borderBottom: '2px solid #34495e', 
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', 
  },
}));

// Função para transformar links em elementos clicáveis
const transformLinks = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = text.split(urlRegex);
  
  return parts.map((part, index) =>
    urlRegex.test(part) ? (
      <a key={index} href={part} target="_blank" rel="noopener noreferrer" style={{ color: '#007bff', textDecoration: 'underline' }}>
        {part}
      </a>
    ) : (
      part
    )
  );
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_USERS":
      return action.payload;
    case "UPDATE_USER":
      return state.map(user =>
        user.id === action.payload.id ? action.payload : user
      );
    default:
      return state;
  }
};

export default function ChatMessages({
  chat,
  messages,
  handleSendMessage,
  handleLoadMore,
  scrollToBottomRef,
  pageInfo,
  loading,
}) {
  const classes = useStyles();
  const { user, socket } = useContext(AuthContext);
  const { datetimeToClient } = useDate();
  const baseRef = useRef();

  const [contentMessage, setContentMessage] = useState("");
  const [users, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    const userList = chat.users.map((u) => ({
      id: u.user.id,
      name: u.user.name,
      profileImage: u.user.profileImage,
      companyId: u.user.companyId,
    }));
    dispatch({ type: "LOAD_USERS", payload: userList });
  }, [chat]);

  useEffect(() => {
    const companyId = user.companyId;
    const onUserUpdate = (data) => {
      dispatch({ type: "UPDATE_USER", payload: data.user });
    };

    socket.on(`company-${companyId}-user`, onUserUpdate);
    return () => {
      socket.off(`company-${companyId}-user`, onUserUpdate);
    };
  }, [socket, user]);

  const scrollToBottom = () => {
    if (baseRef.current) {
      baseRef.current.scrollIntoView({});
    }
  };

  useEffect(() => {
    scrollToBottomRef.current = scrollToBottom;
  }, [scrollToBottomRef]);

  const renderProfileImage = (user, isLarge = false) => {
    const imageUrl = user.profileImage
      ? `${backendUrl}/public/company${user.companyId}/user/${user.profileImage}`
      : `${backendUrl}/public/nopicture.png`;

    return (
      <Avatar src={imageUrl} alt={user.name} className={isLarge ? classes.avatarLarge : classes.avatar} />
    );
  };

  return (
    <Paper className={classes.mainContainer}>
      <Box className={classes.usersBar} display="flex" flexDirection="column" alignItems="flex-start">
        <Box display="flex" alignItems="center">
          <img src={grupimg} alt="Chat Icon" className={classes.userImage} />
          <Typography variant="h6" style={{ margin: "20px" }}>{chat.title}</Typography>
        </Box>
        <Box style={{ display: 'flex', marginTop: "10px", overflowX: 'auto' }}>
          {users.map((user, index) => (
            <Box key={user.id} display="flex" alignItems="flex-start" style={{ margin: "0 3px" }}>
              <Typography variant="body2" style={{ color: '#ecf0f1' }}>
                {user.name}{index < users.length - 1 ? ', ' : ''}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <div onScroll={handleLoadMore} className={classes.messageList}>
        {Array.isArray(messages) &&
          messages.map((item, key) => {
            const isCurrentUser = item.senderId === user.id;
            const sender = users.find(u => u.id === item.senderId) || { name: item.sender.name };

            return (
              <Box key={key} className={isCurrentUser ? classes.boxRight : classes.boxLeft}>
                {renderProfileImage(sender)}
                <div className={classes.messageContent}>
                  <Typography variant="subtitle2" style={{ display: "flex", alignItems: "center", color: "#007bff", fontWeight: 'bold' }}>
                    {sender.name}
                    <UserStatusIcon user={sender} style={{ marginLeft: 8 }} />
                  </Typography>
                  <Typography variant="body1" className={classes.messageText}>
                    {transformLinks(item.message)}
                  </Typography>
                  <Typography variant="caption" display="block">
                    {datetimeToClient(item.createdAt)}
                  </Typography>
                </div>
              </Box>
            );
          })}
        <div ref={baseRef}></div>
      </div>
      <div className={classes.inputArea}>
        <FormControl variant="outlined" fullWidth>
        <Input
  multiline
  value={String(contentMessage)} // Converte contentMessage para string, garantindo que nunca seja undefined ou null
  onChange={(e) => setContentMessage(e.target.value)}
  onKeyDown={(e) => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault(); // Evita o comportamento padrão de Enter
      setContentMessage((prev) => String(prev) + "\n"); // Adiciona quebra de linha
    } else if (e.key === "Enter") {
      e.preventDefault(); // Evita envio vazio
      if (String(contentMessage).trim()) { // Garante que o conteúdo não seja vazio
        handleSendMessage(contentMessage.trim());
        setContentMessage(""); // Reseta o campo
      }
    }
  }}
  className={classes.input}
  endAdornment={
    <InputAdornment position="end">
      <IconButton
        onClick={() => {
          if (String(contentMessage).trim()) { // Garante que não será enviado vazio
            handleSendMessage(contentMessage.trim());
            setContentMessage("");
          }
        }}
        className={classes.buttonSend}
        disabled={!String(contentMessage).trim()} // Desabilita o botão se o texto estiver vazio
      >
        <SendIcon />
      </IconButton>
    </InputAdornment>
  }
/>

        </FormControl>
      </div>
    </Paper>
  );
}
