import React, { useState, useEffect, useCallback, useMemo, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  IconButton,
  Menu,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import LowPriorityIcon from "@material-ui/icons/LowPriority";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "../../context/Auth/AuthContext";
import axios from "axios";
import { getBackendUrl } from "../../config";


const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    marginBottom: "1rem",
  },
  input: {
    width: "200px",
  },
  select: {
    width: "150px",
  },
  taskDetails: {
    fontSize: "0.9rem",
    color: "#555",
    marginTop: "5px",
  },
  weekContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    backgroundColor: "#f5f5f5",
    borderRadius: "5px",
    padding: "1rem",
  },
  daySection: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRight: "1px solid #ddd",
    padding: "1rem",
    "&:last-child": {
      borderRight: "none",
    },
  },
  dayTitle: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    marginBottom: "1rem",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    width: "100%",
  },
  listItem: {
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "1rem",
    textAlign: "center",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
    transition: "background-color 0.3s ease",
  },
  actionsContainer: {
    marginTop: "0.5rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "0.5rem",
  },
});
const backendUrl = getBackendUrl();
const API_URL = `${backendUrl}/tasks`;

const ToDoListWithModifiedView = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [task, setTask] = useState("");
  const [selectedDay, setSelectedDay] = useState("segunda");
  const [priority, setPriority] = useState("baixa");
  const [tasks, setTasks] = useState([]);
  const [editingTask, setEditingTask] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteAllDialogOpen, setDeleteAllDialogOpen] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const [showAllTasks, setShowAllTasks] = useState(false);

  const [priorityAnchor, setPriorityAnchor] = useState(null);
  const [priorityTaskId, setPriorityTaskId] = useState(null);
  // Novos estados para usuários e filtro por usuário
  const [users, setUsers] = useState([]); // Lista de usuários únicos
  const [selectedUserId, setSelectedUserId] = useState(null); // Usuário selecionado para filtragem

  const daysOfWeek = useMemo(
    () => [
      { id: "segunda", name: "segunda" },
      { id: "terca", name: "terça" },
      { id: "quarta", name: "quarta" },
      { id: "quinta", name: "quinta" },
      { id: "sexta", name: "sexta" },
      { id: "sabado", name: "sábado" },
    ],
    []
  );

  const priorities = useMemo(
    () => [
      { label: "Baixa", value: "baixa", icon: <LowPriorityIcon /> },
      { label: "Média", value: "media", icon: <PriorityHighIcon /> },
      { label: "Alta", value: "alta", icon: <PriorityHighIcon style={{ color: "red" }} /> },
      { label: "Concluído", value: "concluido", icon: <CheckCircleIcon style={{ color: "green" }} /> },
    ],
    []
  );

  const creationPriorities = useMemo(
    () => priorities.filter((priority) => priority.value !== "concluido"),
    [priorities]
  );

  useEffect(() => {
    fetchTasks();
    const interval = setInterval(() => {
      fetchTasks();
    }, 5000);
    return () => clearInterval(interval);
  }, [showAllTasks, selectedUserId]);

  const fetchTasks = async () => {
    try {
        // Monta a URL com base nos parâmetros
        const endpoint = showAllTasks
            ? `${API_URL}?companyId=${user.companyId}` // Para todas as tarefas da empresa
            : `${API_URL}?userId=${user.id}&companyId=${user.companyId}`; // Inclui companyId ao buscar tarefas do usuário

        const response = await axios.get(endpoint);
        setTasks(response.data);

         // Extrair usuários únicos ao buscar todas as tarefas
      if (showAllTasks) {
        const uniqueUsers = Array.from(
          new Map(response.data.map((task) => [task.userId, task.User.name]))
        ).map(([userId, name]) => ({ userId, name }));
        setUsers(uniqueUsers);
      }
    } catch (error) {
        console.error("Erro ao buscar tarefas:", error);
    }
};

  const saveTask = async (newTask) => {
    try {
      const response = await axios.post(API_URL, { ...newTask, userId: user.id });
      setTasks((prev) => [...prev, response.data]);
    } catch (error) {
      console.error("Erro ao salvar tarefa:", error);
    }
  };

  const updateTask = async (updatedTask) => {
    try {
      await axios.put(`${API_URL}/${updatedTask.id}`, { ...updatedTask, userId: user.id });
      setTasks((prev) =>
        prev.map((task) => (task.id === updatedTask.id ? updatedTask : task))
      );
    } catch (error) {
      console.error("Erro ao atualizar tarefa:", error);
    }
  };

  const deleteTask = async () => {
    if (!taskToDelete) return;
    try {
      await axios.delete(`${API_URL}/${taskToDelete}`);
      setTasks((prev) => prev.filter((task) => task.id !== taskToDelete));
      setTaskToDelete(null);
      setDeleteDialogOpen(false);
      toast.success("Tarefa excluída com sucesso!");
    } catch (error) {
      console.error("Erro ao excluir tarefa:", error);
      toast.error("Erro ao excluir tarefa!");
    }
  };

  const deleteAllTasks = async () => {
    try {
      const endpoint = `${API_URL}?userId=${user.id}`;
      await axios.delete(endpoint);
      setTasks([]);
      setDeleteAllDialogOpen(false);
      toast.success("Todas as suas tarefas foram excluídas com sucesso!");
    } catch (error) {
      console.error("Erro ao excluir todas as tarefas:", error);
      toast.error("Erro ao excluir todas as tarefas!");
    }
  };
   // Filtrar tarefas pelo usuário selecionado
   const filterTasksByUser = () => {
    const filteredTasks = selectedUserId
      ? tasks.filter((task) => task.userId === selectedUserId)
      : tasks;
  
    // Ordenar por prioridade (alta vem primeiro)
    return filteredTasks.sort((a, b) => {
      if (a.priority === "alta" && b.priority !== "alta") return -1;
      if (b.priority === "alta" && a.priority !== "alta") return 1;
      return 0; // Mantém a ordem para outras prioridades
    });
  };
  

  const handleAddTask = () => {
    if (!task.trim()) return;
    const newTask = {
        id: editingTask || uuidv4(),
        text: task,
        day: selectedDay,
        priority: priority,
        createdAt: new Date(),
        userId: user.id,
        companyId: user.companyId, // Incluído o companyId
    };

    if (editingTask) {
        updateTask(newTask);
        setEditingTask(null);
    } else {
        saveTask(newTask);
    }
    setTask("");
};

  const handlePriorityClick = (event, taskId) => {
    setPriorityAnchor(event.currentTarget);
    setPriorityTaskId(taskId);
  };

  const handlePriorityClose = () => {
    setPriorityAnchor(null);
    setPriorityTaskId(null);
  };

  const updateTaskPriority = async (newPriority) => {
    const taskToUpdate = tasks.find((task) => task.id === priorityTaskId);
    if (!taskToUpdate) return;

    const updatedTask = { ...taskToUpdate, priority: newPriority };

    try {
      await updateTask(updatedTask);
      handlePriorityClose();
      toast.success("Prioridade atualizada com sucesso!");
    } catch (error) {
      console.error("Erro ao atualizar prioridade:", error);
      toast.error("Erro ao atualizar a prioridade!");
    }
  };

  const handleEditTask = useCallback(
    (taskId) => {
      const taskToEdit = tasks.find((t) => t.id === taskId);
      if (taskToEdit) {
        setTask(taskToEdit.text);
        setSelectedDay(taskToEdit.day);
        setPriority(taskToEdit.priority);
        setEditingTask(taskToEdit.id);
      }
    },
    [tasks]
  );

  return (
    <div className={classes.root}>
      <div className={classes.inputContainer}>
        <TextField
          className={classes.input}
          label="Nova tarefa"
          value={task}
          onChange={(e) => setTask(e.target.value)}
          variant="outlined"
          multiline
          rows={2}
        />
        <TextField
          select
          className={classes.select}
          label="Dia"
          value={selectedDay}
          onChange={(e) => setSelectedDay(e.target.value)}
          variant="outlined"
        >
          {daysOfWeek.map((day) => (
            <MenuItem key={day.id} value={day.name}>
              {day.name.charAt(0).toUpperCase() + day.name.slice(1)}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          className={classes.select}
          label="Prioridade"
          value={priority}
          onChange={(e) => setPriority(e.target.value)}
          variant="outlined"
        >
          {creationPriorities.map((p) => (
            <MenuItem key={p.value} value={p.value}>
              {p.label}
            </MenuItem>
          ))}
        </TextField>
        <Button variant="contained" color="primary" onClick={handleAddTask}>
          {editingTask ? "Salvar" : "Adicionar"}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setDeleteAllDialogOpen(true)}
        >
          Excluir Tudo
        </Button>
        {user.profile === "admin" && (
          <FormControlLabel
            control={
              <Checkbox
                checked={showAllTasks}
                onChange={(e) => setShowAllTasks(e.target.checked)}
                name="showAllTasks"
                color="primary"
              />
            }
            label="Mostrar por usuário"
          />
        )}
        {showAllTasks && users.length > 0 && (
          <TextField
            select
            className={classes.select}
            label="Filtrar por Usuário"
            value={selectedUserId || ""}
            onChange={(e) => setSelectedUserId(Number(e.target.value))}
            variant="outlined"
          >
            <MenuItem value="">Todos os usuários</MenuItem>
            {users.map((user) => (
              <MenuItem key={user.userId} value={user.userId}>
                {user.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      </div>
      <div className={classes.weekContainer}>
  {daysOfWeek.map((day) => (
    <div key={day.id} className={classes.daySection}>
      <div className={classes.dayTitle}>
        {day.name.charAt(0).toUpperCase() + day.name.slice(1)}
      </div>
      <div className={classes.list}>
        {filterTasksByUser()
          .filter((task) => task.day === day.name)
          .map((task) => (
            <div
              key={task.id}
              className={classes.listItem}
              style={{
                backgroundColor:
                  task.priority === "baixa"
                    ? "#d3d3d3"
                    : task.priority === "media"
                    ? "#ffeb99"
                    : task.priority === "alta"
                    ? "#f28b82"
                    : "#d4edda",
              }}
            >
              <div>{task.text}</div>
              <div className={classes.taskDetails}>
                Prioridade: {task.priority}
              </div>
              <div className={classes.taskDetails}>
                Criado em: {new Date(task.createdAt).toLocaleString()}
              </div>
              {user.profile === "admin" && task.User && (
                <div className={classes.taskDetails}>
                  Criado por: {task.User.name}
                </div>
              )}
              {task.userId === user.id && (
                <div className={classes.actionsContainer}>
                  <IconButton
                    onClick={() => handleEditTask(task.id)}
                    aria-label="Editar tarefa"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setTaskToDelete(task.id);
                      setDeleteDialogOpen(true);
                    }}
                    aria-label="Excluir tarefa"
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    onClick={(e) => handlePriorityClick(e, task.id)}
                    aria-label="Alterar prioridade"
                  >
                    <PriorityHighIcon />
                  </IconButton>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  ))}
</div>

      <Menu
        anchorEl={priorityAnchor}
        keepMounted
        open={Boolean(priorityAnchor)}
        onClose={handlePriorityClose}
      >
        {priorities.map((priority) => (
          <MenuItem
            key={priority.value}
            onClick={() => updateTaskPriority(priority.value)}
          >
            {priority.icon}
            {priority.label}
          </MenuItem>
        ))}
      </Menu>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja excluir esta tarefa?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={deleteTask} color="secondary">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteAllDialogOpen}
        onClose={() => setDeleteAllDialogOpen(false)}
      >
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja excluir todas as suas tarefas?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteAllDialogOpen(false)}
            color="primary"
          >
            Cancelar
          </Button>
          <Button onClick={deleteAllTasks} color="secondary">
            Excluir Tudo
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ToDoListWithModifiedView;
